class PriceTable {

    id;
    component;
    input;
    labels;
    checked;

    constructor(id, checked = false) {
        this.id = id;
        this.checked = checked;
        this.component = document.getElementById(this.id);
        this.input = document.querySelector('.js-input');
        this.labels = document.querySelectorAll('.js-label')

        this.input.addEventListener('change', function (event) {
            this.updateState(event.target.checked);
        }.bind(this))
    }

    updateState(checked) {
        this.checked = checked;

        this.labels.forEach(function (label) {
            let checked = label.querySelector('.js-checked');
            let unchecked = label.querySelector('.js-unchecked');

            if (this.checked) {
                checked.classList.remove('is-hidden');
                unchecked.classList.add('is-hidden');
            } else {
                checked.classList.add('is-hidden');
                unchecked.classList.remove('is-hidden');
            }
        }.bind(this))
    }

}

class KeyFeatures {

    id;
    component;
    details;
    images;

    constructor(id) {
        this.id = id;
        this.component = document.getElementById(this.id);
        this.details = this.component.querySelectorAll('.js-details');
        this.images = this.component.querySelectorAll('.js-image');

        this.details.forEach(function (node) {
            node.addEventListener('click', function (event) {
                this.updateState(node)
            }.bind(this))
        }.bind(this))
    }

    updateState(current) {
        this.updateContent(current);
        this.updateImage(current);
    }

    updateContent(current) {
        this.details.forEach(function (node) {
            if (node != current) {
                node.removeAttribute('open');
            }
        })
    }

    updateImage(current) {
        let imageId = current.id.replace('details', 'image');
        let image = this.component.querySelector('#' + imageId);

        image.classList.remove('is-hidden');

        this.images.forEach(function(node) {
            if(node != image) {
                node.classList.add('is-hidden');
            }
        })
    }
}

class NavMobile {

    id;
    component;
    button;
    panel;
    body;

    constructor(id) {
        this.id = id;
        this.component = document.getElementById(this.id);
        this.button = this.component.querySelector('.js-button');
        this.panel = this.component.querySelector('.js-panel');
        this.body = document.querySelector('body');

        this.button.addEventListener('click', function (event) {
            event.preventDefault();
            this.updateState(this.panel)
        }.bind(this))
    }

    updateState(panel) {
        panel.classList.toggle('none');
        panel.classList.toggle('flex');
        this.body.classList.toggle('is-fixed');
    }
}

class CookieBanner {

    id;
    component;
    submit;
    submitAll;
    edit;
    input;
    cookie;
    tabs;

    constructor(id) {
        this.id = id;
        this.component = document.getElementById(this.id);
        this.submit = this.component.querySelector('.js-submit');
        this.submitAll = this.component.querySelector('.js-submit-all');
        this.edit = this.component.querySelector('.js-edit');
        this.input = this.component.querySelector('.js-input');
        this.tabs = this.component.querySelectorAll('.js-tab');

        document.addEventListener('DOMContentLoaded', function(event) { 
            this.cookie = this.getCookie('doriCookie');
            
            this.initCookieBanner();

            if(this.cookie != null) {
                window.dataLayer.push({
                    'event': 'updateCookie',
                    'cookie': this.cookie
                });
            }

            if(this.cookie == null) {
                this.showCookieBanner();
            }
        }.bind(this));

        this.submitAll.addEventListener('click', function(event) {
            event.preventDefault();
            this.cookie = 1;
            this.setCookie('doriCookie', 1, 90);
            window.dataLayer.push({
                'event': 'updateCookie',
                'cookie': 1
            });
            this.hideCookieBanner();
        }.bind(this));

        this.edit.addEventListener('click', function(event) {
            event.preventDefault();

            this.tabs.forEach(function(tab) {
                tab.classList.toggle('is-hidden');
            });
        }.bind(this));

        this.submit.addEventListener('click', function(event) {
            event.preventDefault();
            this.cookie = (this.input.checked ? 1 : 0);
            this.setCookie('doriCookie', this.cookie, 90);
            window.dataLayer.push({
                'event': 'updateCookie',
                'cookie': (this.input.checked ? 1 : 0)
            });
            this.hideCookieBanner();
        }.bind(this));
    }

    initCookieBanner() {
        if(this.cookie == 1) {
            this.input.checked = true;
        }
    }

    showCookieBanner() {
        this.component.classList.remove('is-hidden');
    }

    hideCookieBanner() {
        this.component.classList.add('is-hidden');
    }

    setCookie(name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; secure; samesite=strict; path=/";
    }
    
    getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }
}

